const colorsMap = [
  '#FFC6D9',
  '#48284A',
  '#916C80',
  '#266DD3',
  '#344055',
  '#888098',
  '#C5C5C5',
  '#2C423F',
  '#C84C09',
  '#420217',
  '#A71D31',
  '#595959',
  '#E6AA68',
  '#D8F793',
  '#413F54',
  '#E43F6F',
  '#9FC2CC',
  '#FFA62B',
  '#119822',
  '#D4D2A5'
]

const formatPartner = userLang => partner => {
  // eslint-disable-next-line camelcase
  const { id, name, logo, slug, services, category, description, summary, only_portuguese, special_action_title, special_action_description, site_url, video_url, customers, contact_emails, badge, opportunities, quota } = partner
  // eslint-disable-next-line camelcase
  const lang = only_portuguese ? 'br' : userLang
  return {
    id,
    // eslint-disable-next-line camelcase
    name: name,
    logo,
    slug: slug,
    services: services && Array.isArray(services[lang]) ? services[lang] : [],
    category: category ? category[lang] : '',
    description: description ? description[lang] : '',
    opportunities: opportunities ? opportunities.br : '',
    // eslint-disable-next-line camelcase
    special_action_title: special_action_title ? special_action_title[lang] : '',
    // eslint-disable-next-line camelcase
    special_action_description: special_action_description ? special_action_description[lang] : '',
    summary: summary ? summary[lang] : '',
    site_url,
    video_url,
    customers,
    contact_emails,
    badge,
    quota
  }
}

const formatKeynote = lang => ({ title, speakers, ...keynote }) => {
  return {
    ...keynote,
    title: typeof title === 'string' ? title : title[lang],
    // eslint-disable-next-line camelcase
    speakers: speakers ? speakers.map(({ job_title, about, ...speaker }) => ({ ...speaker, job_title: job_title[lang], about: about[lang] })) : []
  }
}

const formatUser = state => user => {
  const partner = state.partners.all.find(p => p.id === parseInt(user.partner_id))
  return partner ? { ...user, badge: partner.badge } : user
}

const formatMessage = (state) => (message) => ({
  ...message,
  timestamp: message.timestamp || (new Date(parseInt(message.index.split('-').shift()))),
  user: formatUser(state)(state.users[message.user]),
  replyTo: message.replyTo ? { ...message.replyTo, user: state.users[message.replyTo.user] } : null
})

const formatSession = (state) => (session) => ({
  ...session,
  name: session.type !== 'direct' ? session.name : 'Chat Principal',
  with: session.type === 'direct' ? formatUser(state)(session.participants.find(p => p.id !== state.me.id)) : null,
  messages: session.messages.map(formatMessage(state))
})

export default {
  sessions: state => state.sessions.map(formatSession(state))
    .filter(({ closed, type, messages, pending, unread, byme }) => {
      const isClosed = closed === true
      const isNewDirect = type === 'direct' && messages.length === 0 && !pending && byme

      return !isClosed && !isNewDirect
    })
    .sort((a, b) => a.type === 'public' ? -1 : 1),
  currentSessionId: state => state.currentSessionId,
  currentSession: state => {
    const session = state.sessions.find(({ id }) => id === state.currentSessionId)

    if (session) {
      return formatSession(state)(session)
    }
    return null
  },
  user: state => (id) => formatUser(state)(state.users[id]),
  adminEvents: state => state.admin.events.slice(-30).reverse(),
  filteredAdminEvents: state => types => state.admin.events.filter(({ event }) => types.includes(event)).slice(-30).reverse(),
  adminStats: state => state.admin.stats,
  connected: state => state.ws.connected,
  shouldReconnect: state => state.ws.reconnect,
  fallbackActive: state => state.ws.fallback,
  me: state => state.me,
  status: state => state.status,
  lang: state => state.lang,
  unread: state => state.sessions.map(({ unread }) => unread || 0).reduce((sum, i) => {
    sum += i
    return sum
  }, 0),
  metadata: state => state.metadata,
  partnersFilterText: state => state.partners.filterText,
  partnersFilterCategory: state => state.partners.filterCategory,
  userSearchQuery: state => state.usersSearch.query,
  userSearchOnline: state => state.usersSearch.online,
  userSearchViewer: state => state.usersSearch.viewer,
  userSearchResult: state => state.usersSearch.result.map(id => formatUser(state)(state.users[id])),
  userSearchBar: state => state.usersSearch.open,
  partners: state => state.partners.all.map(formatPartner(state.lang)),
  categories: state => {
    const { lang, partners: { all } } = state

    return all.map((partner, index) => {
      const partnerLang = partner.only_portuguese ? 'br' : lang
      return partner.category ? partner.category[partnerLang] : null
    }).filter(c => c)
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .map((item, index) => ({ name: item, color: colorsMap[index] }))
      .sort((a, b) => a.name > b.name ? 1 : -1)
  },
  filteredPartners: state => {
    const { lang, partners: { all, filterText, filterCategory } } = state

    return all
      .map(formatPartner(lang))
      .filter(item => {
        const regexName = new RegExp(filterText, 'ig')
        return [item.name, item.description, item.category].join(' ').search(regexName) !== -1 ||
          item.services
            .filter(item => (item.search(regexName) !== -1))
            .length
      })
      .filter(item => {
        return filterCategory === 'all' || item.category === filterCategory
      })
  },
  partnerBySlug: (state) => (slug) => formatPartner(state.lang)(state.partners.all.find(p => p.slug === slug)),
  attendants: (state) => (partner) => {
    if (!state.partners.attendants[partner.id]) {
      return []
    }
    return state.partners.attendants[partner.id]
      .map(id => state.users[id])
      .filter(u => u)
      .map(formatUser(state))
  },
  keynotes: (state, getters) => {
    if (state.keynotes.all.length) {
      const modifiedKeynotes = getters.keynoteInterval.enabled
        ? [
          ...state.keynotes.all.slice(0, getters.currentKeynoteIndex),
          {
            ...getters.keynoteInterval,
            date: getters.currentKeynote.date
          },
          ...state.keynotes.all.slice(getters.currentKeynoteIndex)
        ]
        : state.keynotes.all

      return modifiedKeynotes.map(formatKeynote(state.lang))
    }
    return []
  },
  originalKeynotes: (state) => state.keynotes.all.map(formatKeynote(state.lang)),
  keynoteTimer: (state) => state.metadata.keynoteTimer,
  keynoteInterval: (state) => state.metadata.keynoteInterval,
  currentKeynote: (state, getters) => {
    if (state.keynotes.all.length) {
      if (getters.keynoteInterval.enabled) {
        return {
          ...getters.keynoteInterval,
          date: getters.keynoteTimer
        }
      } else {
        return formatKeynote(state.lang)(state.keynotes.all[state.metadata.currentKeynote])
      }
    } else {
      return null
    }
  },
  currentKeynoteIndex: state => parseInt(state.metadata.currentKeynote),
  nextKeynote: (state, getters) => {
    if (state.keynotes.all.length) {
      const index = state.metadata.currentKeynote + (getters.keynoteInterval.enabled ? 0 : 1)
      return formatKeynote(state.lang)(state.keynotes.all[index])
    }
  },
  profileCardOpened: state => state.profileCardOpened,
  videos: state => state.metadata.videos,
  adminSuspendedUsers: state => state.admin.suspended
}
