import Amplify, { Auth, Storage } from 'aws-amplify'
import AwsConfig from '../utils/auth/aws-exports'
Amplify.configure(AwsConfig)

Storage.configure({ level: 'protected' })

const readOnlyAttributes = [
  'custom:order_id',
  'custom:sponsor_name',
  'custom:buyer_id',
  'custom:payment_status',
  'custom:roles',
  'custom:batch_id',
  'custom:credential_type_id',
  'custom:hash',
  'email'
]

export default {
  data () {
    return {
      user: null,
      code: false
    }
  },
  async created () {
    const logged = await this.isLogged()
    if (logged) {
      this.user = await Auth.currentAuthenticatedUser()
    }
  },
  methods: {
    async getAvatar (key) {
      return Storage.get(key, { expires: 60 * 60 * 24 * 3 })
    },
    async saveAvatar (name, contentType, body) {
      let key
      await Storage.put(name, body, {
        contentType,
        acl: 'public-read',
        contentEncoding: 'base64'
      })
        .then(res => {
          key = res.key
        })
        .catch(console.log)

      // const getConfig = { expires: 60 * 60 * 24 * 3 }
      return Storage.get(key)
    },
    async isLogged () {
      try {
        await Auth.currentSession()
        return true
      } catch (e) {
        return false
      }
    },
    async CognitoUser () {
      return Auth.currentAuthenticatedUser()
    },
    async CognitoSession () {
      return await Auth.currentSession()
    },
    async signUp (username, password) {
      try {
        this.user = await Auth.signUp({
          username, password, attributes: { email: username }
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async confirm (username, code) {
      try {
        await Auth.confirmSignUp(username, code)
        return true
      } catch (e) {
        return false
      }
    },
    async resendCode (username) {
      try {
        await Auth.resendSignUp(username)
      } catch (e) {
        throw new Error(e)
      }
    },
    async signIn (username, password) {
      try {
        this.user = await Auth.signIn(username, password)
      } catch (e) {
        throw new Error(e)
      }
    },
    async hashSignIn (username, hash) {
      try {
        this.user = await Auth.signIn(username)
          .then(user => {
            if (user.challengeName === 'CUSTOM_CHALLENGE') {
              return Auth.sendCustomChallengeAnswer(user, hash)
            } else {
              console.error(user)
            }
          })
          .catch(err => console.error(err))
      } catch (e) {
        throw new Error(e)
      }
    },
    async signOut () {
      try {
        await Auth.signOut()
      } catch (e) {
        throw new Error(e.message)
      }
    },
    async providerSignIn (provider) {
      try {
        await Auth.federatedSignIn({ provider })
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateUser (attributes) {
      readOnlyAttributes.forEach(attr => {
        delete attributes[attr]
      })
      try {
        await Auth.updateUserAttributes(this.user, attributes)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
