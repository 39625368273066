import Vue from 'vue'
import router from '@/router'

export default {
  addSession (state, session) {
    if (!state.sessions.find(({ id }) => id === session.id)) {
      state.sessions.push({ historySize: 100, ...session })
    }
  },
  setMe (state, { sub, i18n, lang, ...me }) {
    state.lang = lang || (me.country === 'Brasileiro' ? 'br' : 'en')
    state.me = { id: sub, ...me }
    if (i18n) {
      i18n.locale = ['pt-BR', 'br'].includes(state.lang) ? 'pt-BR' : 'en'
    }
  },
  setStatus (state, status) {
    state.status = status
  },
  setSessionAsRead (state, sessionId) {
    const session = state.sessions.find(({ id }) => id === sessionId)
    if (session) {
      Vue.set(session, 'unread', 0)
    }
  },
  setCurrentSession (state, session) {
    const s = state.sessions.find(({ id }) => id === session.id)
    if (s) {
      Vue.set(s, 'unread', 0)
      Vue.set(s, 'closed', false)
    }
    state.usersSearch.open = false
    state.currentSessionId = session.id
  },
  closeSession (state, session) {
    const s = state.sessions.find(s => s.id === session.id)

    if (s) {
      Vue.set(s, 'closed', true)
      state.currentSessionId = state.sessions.find(s => s.type === 'public').id
    }
  },
  sessionAtTheTop (state, session) {
    Vue.set(state.sessions.find(s => s.id === session.id), 'atTheTop', true)
  },
  replaceSession (state, { pendingSession, session }) {
    state.sessions.splice(state.sessions.findIndex(s => s === pendingSession), 1, session)
    if (state.currentSessionId === pendingSession.id) {
      state.currentSessionId = session.id
    }
  },
  upsertUser (state, user) {
    Vue.set(state.users, user.id, { ...(state.users[user.id] || {}), ...user })
  },
  prependMessages (state, { session, messages }) {
    session.messages = [...messages, ...session.messages]
    session.historySize = Math.max(session.historySize, session.messages.length)
  },
  newMessage (state, message) {
    const session = state.sessions.find(({ id }) => id === message.sessionId)
    Vue.set(session, 'closed', false)

    if (session) {
      if (message.pending) {
        message.user = state.me.id
        session.messages.push({ ...message, user: state.me.id, timestamp: Date.now(), replyTo: message.replyTo ? session.messages.find(m => m.index === message.replyTo) : null })
      } else if (message.user && message.user.id === state.me.id) {
        const index = session.messages.findIndex(m => m.id === message.id)
        session.messages.splice(index, 1, { ...message, pending: false, user: message.user.id })
      } else {
        Vue.set(state.users, message.user.id, { ...(state.users[message.user.id] || {}), ...message.user })
        session.messages.push({ ...message, user: message.user.id })
      }
    }

    session.messages.splice(0, session.messages.length - session.historySize)

    const fromMe = message.user === state.me.id || (message.user && message.user.id === state.me.id)
    const isCurrentSession = state.currentSessionId === session.id
    const isReplyToMe = !fromMe && message.replyTo && message.replyTo.user === state.me.id
    const isOnPublicSession = session.type === 'public'
    const isChatVisible = ['Live', 'Conversations', 'Admin'].includes(router.currentRoute.name)

    const isOnFocus = isCurrentSession && isChatVisible
    const replyOnPublicSession = isReplyToMe && isOnPublicSession

    // console.log({ fromMe, isCurrentSession, isReplyToMe, isOnPublicSession, isChatVisible, isOnFocus, replyOnPublicSession }, message)

    if (!fromMe && (
      (replyOnPublicSession && !isOnFocus) ||
      (!isOnFocus && !isOnPublicSession)
    )) {
      if (session.unread !== undefined) {
        session.unread++
      } else {
        session.unread = 1
      }
    }
  },
  deleteMessage (state, { sessionId, index }) {
    const session = state.sessions.find(({ id }) => id === sessionId)
    const message = session.messages.find(({ index: i }) => index === i)
    Vue.set(message, 'deleted', true)
  },
  blockMessage (state, { sessionId, id }) {
    const session = state.sessions.find(({ id }) => id === sessionId)
    if (session) {
      const message = session.messages.find(({ id: i }) => id === i)
      Vue.set(message, 'blocked', true)
    }
  },
  setMetadata (state, { key, value }) {
    if (Object.prototype.toString.call(value) === '[object Object]') {
      Object.entries(value).map(([innerKey, innerValue]) => {
        Vue.set(state.metadata[key], innerKey, innerValue)
      })
    } else {
      Vue.set(state.metadata, key, value)
    }
  },
  newAdminEvent (state, event) {
    state.admin.events.push({ ...event, timestamp: Date.now() })
    state.admin.events.splice(0, state.admin.events.length - 1000)
  },
  setAdminStats (state, payload) {
    state.admin.stats = { ...state.admin.stats, ...payload }
  },
  setConnected (state, connected) {
    state.ws.connected = connected
    state.ws.since = connected ? new Date() : null
  },
  setReconnect (state, reconnect) {
    state.ws.reconnect = reconnect
  },
  setFallback (state, fallback) {
    state.ws.fallback = fallback
  },
  setPartners (state, partners) {
    state.partners.all = partners
  },
  setPartnersFilterText (state, text) {
    state.partners.filterText = text
  },
  setPartnersFilterCategory (state, category) {
    state.partners.filterCategory = category
  },
  setUserSearchCriteria (state, criteria) {
    state.usersSearch = { ...state.usersSearch, ...criteria }
  },
  setUserSearchResults (state, profiles) {
    state.usersSearch.result = profiles
  },
  setUserSearchBar (state, enable) {
    state.usersSearch.open = enable
  },
  setPartnerAttendants (state, { id, profiles }) {
    profiles.map(user => Vue.set(state.users, user.id, { ...(state.users[user.id] || {}), ...user }))
    Vue.set(state.partners.attendants, id, profiles.map(({ id }) => id))
  },
  setKeynotes (state, keynotes) {
    state.keynotes.all = keynotes.talks
  },
  setLang (state, lang) {
    state.lang = lang
  },
  addSuspendedUsers (state, users) {
    users.map(user => {
      const exists = state.admin.suspended.findIndex(u => u.id === user.id)
      if (exists === -1) {
        state.admin.suspended.push(user)
      }
    })
  },
  remSuspendedUsers (state, user) {
    const exists = state.admin.suspended.findIndex(u => u.id === user.id)
    if (exists > -1) {
      state.admin.suspended.splice(exists, 1)
    }
  }
}
