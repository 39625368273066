<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created () {
    if (process.env.VUE_APP_ENV !== 'dev' && window.TrackJS) {
      console.log('Install TrackJS')
      window.TrackJS.install({
        token: '5c8130219d474496bfd2c0a1cbcbf9ed',
        application: `joinvent-${process.env.VUE_APP_ENV}`,
        version: process.env.VUE_APP_VERSION || process.env.VUE_APP_VERCEL_GITHUB_COMMIT_SHA || 'dev'
        // for more configuration options, see https://docs.trackjs.com
      })
    }

    if (process.env.NODE_ENV === 'production') {
      window.OneSignal = window.OneSignal || []
      window.OneSignal.push(() => {
        window.OneSignal.init({
          appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production'
        })
      })
    }
  }
}
</script>
