import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    me: {},
    ws: {
      connected: false,
      since: null,
      reconnect: true,
      fallback: false
    },
    lang: 'br',
    status: 'offline',
    currentSessionId: false,
    users: {},
    usersSearch: {
      result: [],
      query: '',
      open: false,
      online: true,
      viewers: false
    },
    sessions: [],
    metadata: {
      partners: '',
      keynotes: '',
      currentKeynote: 0,
      keynoteTimer: null,
      keynoteInterval: { enabled: false, label: '', title: '', subtitle: '' },
      videos: [],
      showUserCount: false
    },
    partners: {
      all: [],
      filterText: '',
      filterCategory: 'all',
      attendants: {}
    },
    keynotes: {
      all: []
    },
    admin: {
      events: [],
      stats: {},
      suspended: []
    },
    profileCardOpened: false
  },
  getters,
  mutations,
  actions,
  modules: {
  }
})
