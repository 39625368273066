import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import VueTippy, { TippyComponent } from 'vue-tippy'
import '../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss'
import '../node_modules/@fortawesome/fontawesome-pro/scss/light.scss'
import '../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss'
import '../node_modules/@fortawesome/fontawesome-pro/scss/brands.scss'
import '../node_modules/@fortawesome/fontawesome-pro/scss/duotone.scss'
import './assets/styles/main.scss'
import i18n from './i18n'
import VueSanitize from 'vue-sanitize'
import VueNativeNotification from 'vue-native-notification'
if (process.env.NODE_ENV !== 'production') {
  require('./registerServiceWorker')
}

Vue.use(VueSanitize)
Vue.config.productionTip = false

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

Vue.use(VueTippy, {
  directive: 'tippy',
  flipDuration: 0,
  duration: 120,
  animation: 'scale',
  arrow: true,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: true
      }
    }
  }
})
Vue.component('tippy', TippyComponent)
Vue.use(require('vue-moment'))

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
  mounted: () => {
    document.dispatchEvent(new Event('x-app-rendered'))
  }
}).$mount('#app')
