import Moment from 'moment'

const {
  VUE_APP_OPENING_TIME: platform,
  VUE_APP_CHAT_OPENING_TIME: chat,
  VUE_APP_ENV: env
} = process.env

const isProd = env === 'prod'

export const openingTime = new Moment(platform)
export const openingChatTime = new Moment(chat)
export const isLive = !isProd || openingTime.diff(new Moment()) < 0
export const isChatLive = !isProd || openingChatTime.diff(new Moment()) < 0
