import axios from 'axios'
import chat from '../mixins/chat'
import { v4 as uuidv4 } from 'uuid'
import { isChatLive } from '@/utils/dates'

const vis = (function () {
  let stateKey, eventKey
  const keys = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange'
  }
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey]
      break
    }
  }
  return function (c) {
    if (c) document.addEventListener(eventKey, c)
    return !document[stateKey]
  }
})()

export default {
  addSession ({ commit, state, dispatch }, session) {
    if (session.participants) {
      session.participants.map(user => commit('upsertUser', user))
    }
    if (session.type === 'direct') {
      const pendingSession = state.sessions.find(s => {
        return s.pending &&
        s.type === 'direct' &&
        s.participants.filter(p => session.participants.map(({ id }) => id).includes(p.id))
      })
      if (pendingSession) {
        commit('replaceSession', { pendingSession, session })
      }
    }
    commit('addSession', session)

    if (session.unread > 0 && session.messages.length > 0) {
      const message = session.messages[session.messages.length - 1]
      dispatch('notifyNewMessage', { message, session })
    }

    if (!state.currentSessionId && session.type === 'public') {
      commit('setCurrentSession', session)
    }
  },
  requestNewSession ({ commit, state }, user) {
    if (!isChatLive) {
      return
    }

    const hasSession = state.sessions
      .find(({ type, participants }) => {
        return type === 'direct' && participants.find(p => p.id === user.id)
      })

    if (hasSession) {
      commit('setCurrentSession', hasSession)
    } else {
      const fakeSession = {
        id: uuidv4(),
        type: 'direct',
        messages: [],
        pending: true,
        participants: [user],
        byme: true
      }
      chat.methods.createSession('direct', null, null, [user.id])
      commit('addSession', fakeSession)
      commit('setCurrentSession', fakeSession)
      commit('setUserSearchBar', false)
    }
  },
  setMe ({ commit, state }, me) {
    const cleanMe = Object
      .entries(me)
      .reduce((acc, [key, value]) => ({ ...acc, [key.replace(/^(cognito|custom):/, '')]: value }), {})

    commit('setMe', cleanMe)
  },
  setSessionAsRead ({ commit, getters }) {
    const currentSession = getters.currentSession
    commit('setSessionAsRead', currentSession.id)
    if (currentSession.type !== 'public') {
      chat.methods.readSession(currentSession)
    }
  },
  changeSession ({ commit, state }, session) {
    if (session.unread) {
      chat.methods.readSession(session)
    }
    commit('setCurrentSession', session)
  },
  closeSession ({ commit, state }, session) {
    chat.methods.closeSession(session)
    commit('closeSession', session)
  },
  notifyNewMessage ({ commit, state }, { message, session }) {
    const isReplyToMe = message.replyTo && message.replyTo.user === state.me.id
    const isOnCurrentSection = state.currentSessionId !== session.id

    if (session.type === 'public' && !isReplyToMe) {
      return
    }

    if (((isOnCurrentSection || isReplyToMe) && !vis()) || !vis()) {
      const user = state.users[message.user.id || message.user]
      this._vm.$notification.show('Nova mensagem', { body: `${user.name}: ${message.text}` }, {
        onclick () {
          commit('setCurrentSession', session)
        }
      })
    }
  },
  newMessage ({ commit, state, dispatch }, message) {
    commit('upsertUser', message.user)

    const session = state.sessions.find(s => s.id === message.sessionId)

    if (!session) {
      chat.methods.getSession({ id: message.sessionId })
    } else {
      commit('newMessage', message)
      dispatch('notifyNewMessage', { message, session })
    }
  },
  messageBlocked ({ commit }, { id, sessionId }) {
    commit('blockMessage', { id, sessionId })
  },
  sendMessage ({ commit, state }, { id, sessionId, question, replyTo, text }) {
    commit('newMessage', { id, sessionId, text, question, replyTo, pending: true })
  },
  deleteMessage ({ commit, state }, payload) {
    commit('deleteMessage', payload)
  },
  updateMetadata ({ commit }, { metadata }) {
    Object.entries(metadata).map(([key, value]) => commit('setMetadata', { key, value }))
  },
  newAdminEvent ({ commit }, event) {
    commit('newAdminEvent', event)
  },
  sessionHistory ({ commit, state }, { users, messages, sessionId }) {
    const session = state.sessions.find(s => s.id === sessionId)

    if (messages.length > 0) {
      commit('prependMessages', { session, messages })
      users.map(user => commit('upsertUser', user))
    } else {
      commit('sessionAtTheTop', session)
    }
  },
  async refreshPartners () {
    const { VUE_APP_API_ENDPOINT } = process.env
    const { data: { url } } = await axios.get(`${VUE_APP_API_ENDPOINT}/getPartners`)
    chat.methods.setMetadata('partners', url)
  },
  async loadPartners ({ commit, state }) {
    const { data } = await axios.get(state.metadata.partners)
    commit('setPartners', data)
  },
  async loadPrevMessages ({ commit, state }, session) {
    if (!session.atTheTop && session.messages.length) {
      chat.methods.getSessionMessages(session.id, session.messages[0].index)
    }
  },
  async loadAttendants ({ commit, state }, partner) {
    chat.methods.getPartnerAttendants([partner.id])
  },
  async refreshKeynotes () {
    const { VUE_APP_API_ENDPOINT } = process.env
    const { data: { url } } = await axios.get(`${VUE_APP_API_ENDPOINT}/getKeynotes`)
    chat.methods.setMetadata('keynotes', url)
  },
  async loadKeynotes ({ commit, state }) {
    const { data } = await axios.get(state.metadata.keynotes)
    commit('setKeynotes', data)
  },
  setProfileCardOpened ({ commit, state }, opened) {
    state.profileCardOpened = opened
  },
  setLang ({ commit, state }, payload) {
    commit('setLang', ['pt-BR', 'br'].includes(payload.lang) ? 'br' : 'en')
    localStorage.setItem('preferredLang', state.lang)
    payload.i18n.locale = payload.lang
  },
  setUserSearchCriteria ({ commit, state }, { query, online, viewers }) {
    commit('setUserSearchCriteria', { query, online, viewers })
    chat.methods.searchUsers(query, { online, viewers })
  },
  setProfile ({ commit, state, dispatch }, { session }) {
    dispatch('setMe', session.getIdToken().payload)
    // eslint-disable-next-line camelcase
    const { id, name, picture, job_title, city, country, roles, state: uf, biz_name: company } = state.me
    commit('upsertUser', { id, name, picture, job_title, city, country, roles, state: uf, company })
    chat.methods.setProfile(session.getIdToken().getJwtToken())
  },
  setUserStatus ({ commit }, status) {
    chat.methods.setStatus(status)
    commit('setStatus', status)
  },
  setUserSearchResults ({ commit }, { profiles }) {
    profiles.map(user => commit('upsertUser', user))
    commit('setUserSearchResults', profiles.map(({ id }) => id))
  }
}
