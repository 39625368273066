import Vue from 'vue'
import VueRouter from 'vue-router'
import cognito from '@/mixins/cognito'
import VueGtag from 'vue-gtag'
import Moment from 'moment'
const Finalized = () => import('@/views/Finalized')

Moment.locale('pt_BR')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Finalized',
    component: Finalized
  },
  {
    path: '*',
    redirect: { name: 'Finalized' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueGtag, {
  config: { id: 'UA-20561060-1' },
  disableScriptLoad: true,
  includes: [
    { id: 'UA-20561060-15' }
  ]
}, router)

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const session = await cognito.methods.isLogged()
    if (!session) {
      next({
        name: 'LinkAuth',
        query: { redirect: to.fullPath }
      })
    } else {
      const user = await cognito.methods.CognitoUser()
      if (
        (to.matched.some(record => record.meta.roles) && to.meta.roles !== user.attributes['custom:roles']) ||
        (to.matched.some(record => record.meta.allowed) && !to.meta.allowed(user))
      ) {
        next(to.meta.redirect || { name: 'Profile' })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
