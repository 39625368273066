import { mapGetters, mapMutations, mapActions } from 'vuex'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
let ws

const {
  VUE_APP_CHAT_ENDPOINT: wsEndpoint,
  VUE_APP_API_ENDPOINT: apiEndpoint
} = process.env

export default {
  data () {
    return {
      availableChatActions: {
        'session.create': '{"type": "<public|direct|private>", "name": "<name>", "lang": "br|en", "invite": ["<user_id>",...]}',
        'session.join': '{"sessionId": "<uuid>"}',
        'session.messages.get': '{"sessionId": "<uuid>"}',
        'session.message.post': '{"sessionId": "<uuid>", "text": <message>, "id": <uuid>, "question": true|false}',
        'session.message.delete': '{"sessionId": "<uuid>", "index": "<index>"}',
        'user.autojoin': '{"lang": "br|en"}',
        'user.profile.set': '{"status": "<online|dnd|offline>", "jwt": "<jwt>"}',
        'user.search': '{"query": "query", "online": true|false}',
        'admin.autojoin.add': '{"sessionId": "<uuid>"}',
        'admin.autojoin.rem': '{"sessionId": "<uuid>"}',
        'metadata.get': '{"keys": ["key1",...]}',
        'admin.metadata.set': '{"key": "currentKeynote", "value": 0}',
        'admin.attendants.refresh': '',
        'admin.suspended.get': '{}',
        'admin.suspended.add': '{"user": "<uuid>"}',
        'partner.attendants.get': '{"partners": [id1, id2...]}',
        'admin.stats': ''
      },
      availableUserStatus: {
        online: 'Online',
        dnd: 'Ocupado',
        unavailable: 'Indisponível'
      },
      statsInterval: null,
      pingInterval: null,
      suspendedInterval: null,
      connectTimeout: null,
      fallbackConnection: false,
      reconnectTimeout: 3000,
      initialMetadata: ['partners', 'keynotes', 'currentKeynote', 'keynoteInterval', 'keynoteTimer', 'videos', 'showUserCount']
    }
  },
  computed: {
    ...mapGetters([
      'me',
      'shouldReconnect',
      'lang',
      'sessions',
      'currentSession',
      'userSearchQuery'
    ])
  },
  methods: {
    ...mapMutations(['setConnected', 'setReconnect']),
    ...mapActions({ setStatusState: 'setUserStatus' }),
    async connect () {
      const session = await this.CognitoSession()
      const token = session.getIdToken().getJwtToken()
      ws = new WebSocket(`${wsEndpoint}?Authorization=${token}`)
      ws.onopen = () => {
        clearTimeout(this.connectTimeout)
        this.setConnected(true)
        this.autojoin()
        this.initMetadata()
        this.setStatusState('online')

        if (this.me.roles === 'ADMIN') {
          this.getAdminStats()
          this.getSuspendedUsers()
          this.statsInterval = setInterval(() => { this.getAdminStats() }, 1000 * 10)
          this.suspendedInterval = setInterval(() => { this.getSuspendedUsers() }, 1000 * 60)
        }
      }

      ws.onclose = () => {
        this.setConnected(false)
        clearInterval(this.statsInterval)
        clearInterval(this.suspendedInterval)
        clearInterval(this.pingInterval)

        if (this.shouldReconnect && !this.fallbackConnection) {
          setTimeout(() => this.connect(), this.reconnectTimeout)
        }
      }

      window.onbeforeunload = () => {
        ws.onclose = function () {}
        ws.close()
      }

      ws.onmessage = (message) => { this.parseMessage(message) }

      if (!this.connectTimeout) {
        this.connectTimeout = setTimeout(this.handleConnectionTimeout, 10000)
      }
    },
    async handleConnectionTimeout () {
      this.fallbackConnection = true
      this.$store.commit('setReconnect', false)
      this.$store.commit('setFallback', true)
      const { data: { metadata } } = await axios.get(`${apiEndpoint}/fallback`, { params: { keys: this.initialMetadata.join() } })
      this['metadata.set']({ metadata })
    },
    parseMessage ({ data }) {
      const { event, ...payload } = JSON.parse(data)
      if (this[event] && event !== 'error') {
        this[event](payload)
      } else {
        console.info(`No handler to event: ${event}`, payload)
      }
    },
    send (action, payload) {
      ws.send(JSON.stringify({ action, ...payload }))
    },
    autojoin () {
      this.send('user.autojoin', { lang: this.lang })
    },
    initMetadata () {
      this.send('metadata.get', { keys: this.initialMetadata })
    },
    setStatus (status) {
      this.send('user.profile.set', { status })
    },
    setProfile (jwt) {
      this.send('user.profile.set', { jwt })
    },
    setMetadata (key, value) {
      this.send('admin.metadata.set', { key, value })
    },
    sendMessage ({ sessionId, text, question, replyTo }) {
      const payload = { id: uuidv4(), text, sessionId, question, replyTo }
      this.$store.dispatch('sendMessage', payload)
      this.send('session.message.post', payload)
    },
    deleteMessage (sessionId, index) {
      this.send('session.message.delete', { sessionId, index })
    },
    searchUsers (query, { online, viewers } = { online: true, viewers: false }) {
      this.send('user.search', { query, online, viewers })
    },
    createSession (type, name, lang, invite) {
      this.send('session.create', { type, name, lang, invite })
    },
    getSessionMessages (sessionId, index, question = false) {
      this.send('session.messages.get', { sessionId, index, question })
    },
    getPartnerAttendants (partners) {
      this.send('partner.attendants.get', { partners })
    },
    closeSession (session) {
      this.send('session.close', { sessionId: session.id })
    },
    getSession (session) {
      this.send('session.get', { sessionId: session.id })
    },
    getAdminStats () {
      this.send('admin.stats', { })
    },
    refreshAttendants () {
      this.send('admin.attendants.refresh', { })
    },
    readSession (session) {
      this.send('session.read', { sessionId: session.id })
    },
    getSuspendedUsers () {
      this.send('admin.suspended.get', { })
    },
    addSuspendedUser (user, time = 600) {
      this.send('admin.suspended.add', { user: user.id })
    },
    remSuspendedUser (user) {
      this.send('admin.suspended.rem', { user: user.id })
    },
    'stats' (payload) {
      this.$store.commit('setAdminStats', payload)
    },
    'partner.attendants.get' ({ profiles, id }) {
      this.$store.commit('setPartnerAttendants', { id, profiles })
    },
    'user.search.results' (payload) {
      this.$store.dispatch('setUserSearchResults', payload)
    },
    'user.connection.new' ({ reconnect }) {
      this.$store.commit('setReconnect', reconnect)
    },
    'session.create' (payload) {
      this.$store.dispatch('addSession', payload)
    },
    'session.message.new' (payload) {
      this.$store.dispatch('newMessage', payload)
    },
    'session.message.delete' (payload) {
      this.$store.dispatch('deleteMessage', payload)
    },
    'session.message.history' (payload) {
      this.$store.dispatch('sessionHistory', payload)
    },
    'metadata.set' ({ metadata }) {
      metadata = Object.entries(metadata).map(([key, value]) => {
        try {
          value = JSON.parse(value)
        } catch (e) {}
        return [key, value]
      }).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      this.$store.dispatch('updateMetadata', { metadata })
    },
    'admin.user.join' (payload) {
      this.$store.dispatch('newAdminEvent', { event: 'admin.user.join', ...payload })
    },
    'admin.user.leave' (payload) {
      this.$store.dispatch('newAdminEvent', { event: 'admin.user.leave', ...payload })
    },
    'admin.metadata.set' (payload) {
      this.$store.dispatch('newAdminEvent', { event: 'admin.metadata.set', ...payload })
    },
    'user.profile.update' (payload) {
      this.$store.dispatch('newAdminEvent', { event: 'user.profile.update', ...payload })
      this.$store.commit('upsertUser', payload.user)
    },
    'admin.suspended.users' ({ users }) {
      this.$store.commit('addSuspendedUsers', users)
    },
    'admin.suspended.add' ({ user }) {
      this.$store.commit('addSuspendedUsers', [user])
    },
    'admin.suspended.rem' ({ user }) {
      this.$store.commit('remSuspendedUsers', user)
    },
    'session.message.blocked' (payload) {
      this.$store.dispatch('messageBlocked', payload)
    },
    'admin.session.create' (payload) {
      this.$store.dispatch('newAdminEvent', { event: 'admin.session.create', ...payload })
    }
  }
}
