import Amplify from 'aws-amplify'

export default Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.VUE_APP_COGNITO_IDENTITY_POOL_REGION,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',
    oauth: {
      domain: 'qa.forumecommerce.com',
      scope: ['email', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_SIGNOUT,
      responseType: 'token'
    }
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_COGNITO_S3_BUCKET_NAME,
      region: process.env.VUE_APP_AWS_REGION
    }
  }
})
